<template>
  <div
    class="flex-1 p-4 cursor-pointer"
    :class="{
      'bg-rendin-500 bg-opacity-5 hover:bg-opacity-10': !hasInteracted,
      'hover:bg-gray-50': hasInteracted,
    }"
  >
    <div class="flex justify-between items-center gap-1">
      <p class="text-md text-gray-900" :class="{ 'font-medium': !hasInteracted }">
        {{ title }}
      </p>
      <div
        class="text-sm"
        :class="{
          'font-medium text-black': !hasInteracted,
          'text-gray-500': hasInteracted,
        }"
      >
        {{ formatRelativeTime(created) }}
      </div>
    </div>
    <p class="text-md text-gray-600">{{ subtitle }}</p>
  </div>
</template>
<script>
import useDateFromIsoString from '~/composables/useDateFromIsoString.js';

export default {
  name: 'BaseNotificationTwoLine',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    created: {
      type: String,
      required: true,
    },
    firstInteractedTime: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const { formatRelativeTime } = useDateFromIsoString();
    return {
      formatRelativeTime,
    };
  },
  computed: {
    hasInteracted() {
      return !!this.firstInteractedTime;
    },
  },
};
</script>
